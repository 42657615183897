//@import './assets/bootstrap-override.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
//<!-- Animation CSS -->
@import './assets/css/animate.css';
@import './assets/bootstrap/css/bootstrap.min.css';

//<!-- Icon Font CSS -->
@import './assets/css/all.min.css';
@import './assets/css/ionicons.min.css';
@import './assets/css/themify-icons.css';
@import './assets/css/linearicons.css';
@import './assets/css/flaticon.css';
@import './assets/css/simple-line-icons.css';
//<!--- owl carousel CSS-->
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
//<!-- Magnific Popup CSS -->
@import './assets/css/magnific-popup.css';
//<!-- Slick CSS -->
@import './assets/css/slick.css';
@import './assets/css/slick-theme.css';
//<!-- Style CSS -->
@import 'assets/css/style';
@import './assets/css/responsive.css';
